import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "./audioplayer.css";

const AudioPlayer = ({
    audioSrc,
    setData,
    isDeletingAllowed = true,
    onLoadedData,
    // direction = "rtl",
}) => {
    const audioRef = useRef(null);
    const progressRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleMetadataLoad = () => {
        const audio = audioRef.current;
        if (audio && isFinite(audio.duration)) {
            setDuration(audio.duration);
        }
    };

    useEffect(() => {
        const audio = audioRef.current;
        const updateTime = () => {
            if (!isDragging) {
                setCurrentTime(audio.currentTime);
            }
        };

        if (audio) {
            audio.addEventListener("timeupdate", updateTime);
            audio.addEventListener("loadedmetadata", handleMetadataLoad);
            return () => {
                audio.removeEventListener("timeupdate", updateTime);
                audio.removeEventListener("loadedmetadata", handleMetadataLoad);
            };
        }
    }, [audioSrc, isDragging]);

    const togglePlayPause = (e) => {
        e.preventDefault();
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    // Handle progress bar clicks
    const handleProgressClick = (e) => {
        const progressWidth = progressRef.current.clientWidth;
        const offsetX = e.nativeEvent.offsetX;
        const seekTime = (offsetX / progressWidth) * duration;
        audioRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const handleDragStart = () => setIsDragging(true);

    const handleDrag = (e) => {
        if (isDragging && progressRef.current) {
            const progressWidth = progressRef.current.clientWidth;
            const offsetX = e.nativeEvent.offsetX;
            const newTime = (offsetX / progressWidth) * duration;
            setCurrentTime(newTime);
        }
    };

    const handleDragEnd = () => {
        if (isDragging) {
            audioRef.current.currentTime = currentTime;
            setIsDragging(false);
        }
    };

    const formatTime = (time) => {
        if (!isFinite(time) || time === 0) return "0:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60)
            .toString()
            .padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="whatsapp-audio-player w-full bg-orange-100 dark:bg-gray-800 smooth p-3 rounded-lg shadow-md flex items-center space-x-3"
        >
            <div className="rounded-full w-14 h-10 bg-gray-300 flex items-center justify-center">
                <Icon icon="mdi:account" className="text-gray-500" width="20" />
            </div>
            <button
                onClick={togglePlayPause}
                className="text-gray-600 p-2 rotate-180 rounded-full focus:outline-none"
            >
                <Icon icon={isPlaying ? "mdi:pause" : "mdi:play"} width="24" />
            </button>

            <div
                ref={progressRef}
                className="relative flex-grow h-1 bg-gray-300 rounded-full cursor-pointer"
                onClick={handleProgressClick}
                onMouseDown={handleDragStart}
                onMouseMove={handleDrag}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
            >
                <div
                    className="absolute h-1 bg-orange-500 rounded-full"
                    style={{ width: `${(currentTime / duration) * 100}%` }}
                ></div>
                <div
                    className="absolute h-3 w-3 bg-orange-500 rounded-full transform -translate-y-1/2 top-1/2 cursor-pointer"
                    style={{ right: `${(currentTime / duration) * 100}%` }}
                    onMouseDown={handleDragStart}
                ></div>
            </div>
            <span className="text-xs w-24 text-gray-500">
                {formatTime(currentTime)} / {formatTime(duration)}
            </span>

            <audio
                ref={audioRef}
                src={audioSrc}
                preload="metadata"
                onLoadedData={onLoadedData}
                onError={(e) => {
                    e.target.style.display = "none";
                    document.getElementById("audio-error-message").style.display = "block";
                }}
            />
            <span
                id="audio-error-message"
                style={{ display: "none" }}
                className="text-red-600 text-sm mt-2"
            >
                متصفحك الخاص بك لا يدعم ملف الصوت
            </span>
            {isDeletingAllowed && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setData((prevData) => ({ ...prevData, audio_source: "" }));
                    }}
                    className="text-red-500 smooth hover:text-red-800 p-2 rounded-full bg-gray-300 dark:bg-gray-700"
                >
                    <Icon width="18" icon="weui:delete-on-filled" className="w-6 h-6" />
                </button>
            )}
        </div>
    );
};

export default AudioPlayer;
