import React from "react";
import { Outlet } from "react-router-dom";
import HeaderSection from "../components/ui/HeaderSection";
import Container from "../components/ui/Container";

import { ReactComponent as CommunityHeroImg } from "../assets/Updated-imagery/communityHero.svg";
import BgInkPattern from "../assets/Updated-imagery/inkpattern.png";
const CommunityHome = () => {
    return (
        <>
            <div className="bg-outer-container  smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <Container className="py-10 pb-10  space-y-0">
                    <div className="w-full rounded-2xl py-10 md:py-2 px-10 md:px-0 bg-orange-100 dark:bg-slate-800 smooth relative overflow-hidden z-10 flex md:flex-row-reverse flex-col h-full space-y-10 md:space-y-0 ">
                        <img
                            src={BgInkPattern}
                            alt="BgInkPattern"
                            className="absolute inset-0 -z-10 opacity-70 dark:opacity-45"
                        />
                        <div className="basis-full md:basis-1/2 z-10 flex-center-both">
                            <div className="flex md:items-start  justify-center flex-col space-y-10 md:space-y-0 font-graphic">
                                <h1 className="font-vip clr-text-primary smooth w-full font-bold text-3xl lg:text-4xl text-center md:text-right xl:text-5xl">
                                    وراك لحد
                                    <br />
                                    <span className="text-orange-500 pt-2">الدرجة النهائية؟!</span>
                                </h1>
                                <p className="text-center md:text-right clr-text-primary smooth font-lama w-full pt-5 md:pt-10 lg:w-[60%]">
                                    قرّبنالك كل حاجة! دلوقتي في منتدى <strong>أستاذي</strong> مع
                                    مستر <strong>وليد محسن</strong>، هنكون معاك خطوة بخطوة لحد ما
                                    تبقى متمكن في اللغة العربية. اسأل براحتك، شوف أسئلة زمايلك،
                                    وفريق <strong>أستاذي</strong> هيجاوبك ويوجهك. تابع، اتعلم، واسأل
                                    بدون تردد!
                                </p>
                            </div>
                        </div>
                        <div className="basis-full md:basis-1/2 z-10 h-full flex-center-both pt-10 md:pt-0">
                            <div className="flex-center-both max-w-3xl px-10 sm:px-10">
                                <CommunityHeroImg className="w-full" />
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="w-full  relative  clr-text-primary smooth">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default CommunityHome;
