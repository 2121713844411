import loginPageIconImport from "../assets/login.png";
import registerPageIconImport from "../assets/register.svg";
import { baseURL } from "../config";

export const adminPath = "waleed-admin";
export const teachersubadminPath = "dasdas-teacher-admin";
export const domainName = "waleed-mohsen.com";

export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "teal";
export const headerSectionColor = "yellow";
export const navbarIconColor = "text-teal-500";
export const navbarDisclosureColor = "teal";

export const progressBarColor = "teal";
export const loadingBarColor = "teal";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "teal";

export const autoChangeRandomNames = false;
export const autoChangeCenterWithNames = false;

export const isCenters = false;

export const navThemeRTL = false;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "rose";
export const registerPageColor = "teal";

export const isAnalytics = false;

export const insertAutos = {
    "back-1": { link: `${baseURL}/insert_auto_card/back/1/`, name: "عرض كارت ١ - خلفية" },
    "back-2": { link: `${baseURL}/insert_auto_card/back/2/`, name: "عرض كارت ٢ - خلفية" },
};

export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = false;

export const isAccountCreationRequest = true;

export const showExamStatisticsTable = true;
export const showVideoStatisticsTable = true;

export const isCategories = true;

export const isGlobalNotifications = true;

export const isRegisterationMiddleName = false;
export const isRegisterationFatherJob = false;
export const isRegisterationGender = false;

export const isCollapseCategories = false;

export const isWrongCode = false;

export const isUserStatisticsTable = true;

export const isRemotePlatformIntegrationEnabled = true;

export const isYear4CoursesCustomTab = true;

export const isAdminToAdminNote = true;
export const isAdminAbleToChangeUsersYear = true;

export const isOtpEnabled = false;

export const isWalletEnabled = false;

export const isStudyPlannerEnabled = false;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = false;

export const isAdminUsers = true;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = true;
export const isChargeInsertAuto = true;
export const isInsertAutoTypes = true;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = true;

export const isCustomUserExamLimit = true;
export const isCustomUserVideoLimit = true;

export const isCenterApi = true;

export const isLoginAsUser = true;

export const isExamQuestionsFromOneParititonOnly = false;
export const isPreExamInquest = false;

export const isEssayQuestions = true;
export const isMultiMarksQuestion = true;

export const isAdminCourseStatistics = true;

export const isSearch = false;

export const isPinnedCourse = false;

export const isUnassignedSubscription = false;

export const isStoreLocatorAvailable = false;

export const isYoutubeVideoButton = false;

export const isCoursesAreRenamed = false;

export const isFeaturedCourses = true;

export const isPriceBeforeDiscountEnabled = true;

export const isWhatsAppAudioPlayerStyle = true;
export const isCommunityVoiceRecordingEnabled = true;
